<script>
import { PortableText } from "@portabletext/svelte";
import { onMount } from "svelte";
export let section;

onMount(async () => {
  let ScrollMagic;
  let lottie;

  const smModu = await import("scrollmagic");
  ScrollMagic = smModu.default;

  lottie = await import("lottie-web");

  function initAnimations(pJSONdata) {
    const selectors = {
      usingEmailAnimContainer: "using-email",
      usingEmailAnimTrigger: '[data-ui-component="nothing-to-install"]',
    };

    const usingEmailAnimData = pJSONdata;

    const scrollController = new ScrollMagic.Controller({});

    const AnimationConfigWithData = {
      container: document.getElementById(selectors.usingEmailAnimContainer),
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: usingEmailAnimData,
      rendererSettings: {
        progressiveLoad: true,
      },
    };

    const usingEmailAnimation = lottie.loadAnimation(AnimationConfigWithData);

    var usingEmailScene = new ScrollMagic.Scene({
      triggerElement: selectors.usingEmailAnimTrigger,
      triggerHook: 0.6,
    })
      .on("enter", function () {
        usingEmailAnimation.play();
      })
      .addTo(scrollController);
  }

  function loadAnimJSON() {
    let jsonData =
      "https://cdn.sanity.io/files/ebeyjpve/production/36c4a2407bd2ae2227b71bb64b2ff76486a37f49.json";

    return fetch(jsonData).then((response) => {
      if (!response.ok) {
        throw new Error("HTTP Error" + response.status);
      }
      return response.json();
    });
  }

  if (window.innerWidth > 767) {
    loadAnimJSON().then((data) => {
      initAnimations(data);
    });
  } else {
    return;
  }
});
</script>

<section
  class="nothing-to-install-section"
  data-ui-component="nothing-to-install"
>
  <div class="nothing-to-install-section__inner">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="row middle-md">
            <div class="col-sm-12 col-md-5">
              <div class="nothing-to-install-section__image-container">
                <div
                  class="hide-for-medium-up nothing-to-install-section__image"
                >
                  <img
                    src="https://cdn.sanity.io/images/ebeyjpve/production/3e186cce945df7393fc55490495f46bca09facac-473x532.svg"
                    alt="Illustration of a laptop, phone, and tablet pulling Pogo LT reports into their inboxes."
                    loading="lazy"
                  />
                </div>
                <div
                  class="show-for-medium-up nothing-to-install-section__image"
                  id="using-email"
                />
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="nothing-to-install-section__copy">
                <h2 class="nothing-to-install-section__headline headline">
                  {section.headline}
                </h2>

                <div class="nothing-to-install-section__body">
                  <PortableText value={section.body} />
                </div>

                <div class="nothing-to-install-section__cta">
                  {#if section.cta_link.linkType == "internal"}
                    <a
                      rel="internal"
                      href="/{section.cta_link.internalLink.handle.current}"
                      class="button button--primary button--medium nothing-to-install__cta"
                      title="Go to {section.cta_link.text} page"
                    >
                      <span> {section.cta_link.text}</span>
                    </a>
                  {/if}

                  {#if section.cta_link.linkType == "external"}
                    <a
                      href={section.cta_link.href}
                      target={section.cta_link.blank ? "_blank" : null}
                      rel="external"
                      title="Go to external link at {section.cta_link.href}"
                      class="button button--primary button--medium nothing-to-install__cta"
                    >
                      <span> {section.cta_link_text}</span>
                    </a>
                  {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.nothing-to-install-section__inner {
  max-width: 70rem;
  margin-right: auto;
  margin-left: auto;
}
.nothing-to-install-section__cta {
  max-width: 14rem;
}

@media only screen and (min-width: 961px) {
  .nothing-to-install-section {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}

.nothing-to-install-section .nothing-to-install-section__image-container {
  max-width: 22rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  position: relative;
}

@media only screen and (min-width: 961px) {
  .nothing-to-install-section .nothing-to-install-section__image-container {
    max-width: 30rem;
    top: -2rem;
  }
}

.nothing-to-install-section .nothing-to-install-section__image {
  position: relative;
  padding-bottom: 113%;
}

.nothing-to-install-section .nothing-to-install-section__image img,
:global(.nothing-to-install-section .nothing-to-install-section__image svg) {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 768px) {
  .nothing-to-install-section .nothing-to-install-section__copy {
    padding-left: 2.5rem;
    padding-right: 2.25rem;
  }
}

@media only screen and (min-width: 961px) {
  .nothing-to-install-section .nothing-to-install-section__copy {
    padding-left: 4.5rem;
    padding-right: 0;
  }
}

.nothing-to-install-section .nothing-to-install-section__headline {
  max-width: 17.5rem;
  font-size: var(--h2);
  line-height: var(--headline-line-height);
  font-family: var(--secondary-font-family);
}

@media only screen and (min-width: 961px) {
  .nothing-to-install-section .nothing-to-install-section__headline {
    font-size: var(--h1);
  }
}

@media only screen and (min-width: 768px) {
  .nothing-to-install-section .nothing-to-install-section__headline {
    max-width: none;
  }
}

.nothing-to-install-section__body {
  max-width: 48ch;
}
</style>
