<script>
import { PortableText } from "@portabletext/svelte";
import { onMount } from "svelte";
export let section;

onMount(async () => {
  var heroHeaderAnims = {
    animation: null,
    breakpoint: null,
    properties: {
      container: document.getElementById("hero-header-anim"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      rendererSettings: {
        progressiveLoad: true,
      },
    },
    loadAnimJSON: function (pWindowWidth) {
      var that = this;
      if (pWindowWidth < 768) {
        that.breakpoint = "small";

        return fetch("/hero-header-anim-small.json").then((response) => {
          if (!response.ok) {
            throw new Error("HTTP Error" + response.status);
          }
          return response.json();
        });
      }

      if (pWindowWidth < 961 && pWindowWidth > 767) {
        that.breakpoint = "medium";

        return fetch("/hero-header-anim-large.json").then((response) => {
          if (!response.ok) {
            throw new Error("HTTP Error" + response.status);
          }
          return response.json();
        });
      }

      if (pWindowWidth > 961) {
        that.breakpoint = "large";

        return fetch("/hero-header-anim-large.json").then((response) => {
          if (!response.ok) {
            throw new Error("HTTP Error" + response.status);
          }
          return response.json();
        });
      }
    },
    init: async function (pWindowWidth) {
      const lottie = await import("lottie-web");

      const that = this;
      if (that.animation) {
        that.animation.destroy();
      }
      that.loadAnimJSON(pWindowWidth).then((data) => {
        that.properties.animationData = data;
        that.animation = lottie.loadAnimation(that.properties);
        that.animation.setSpeed(0.8);
      });

      window.addEventListener("resize", () => {
        clearTimeout(window.resizedFinished);
        document.querySelector("#hero-header-anim").style.display = "none";

        window.resizedFinished = setTimeout(function () {
          that.init(window.innerWidth);
          document.querySelector("#hero-header-anim").style.display = "block";
        }, 200);
      });

      //   $(window).on('resize', function () {
      //     clearTimeout(window.resizedFinished);
      //     $('#hero-header-anim').css('display', 'none');
      //     window.resizedFinished = setTimeout(function () {
      //       that.init(window.innerWidth);
      //       $('#hero-header-anim').css('display', 'block');
      //     }, 600);
      //   });
      // });
    },
  };

  heroHeaderAnims.init(window.innerWidth);
});
</script>

<section class="section--main introduction" data-ui-component="hero-header">
  <div class="container-fluid">
    <div class="row center-md">
      <div class="col-sm-12">
        <div class="row reverse-md middle-md">
          <div class="col-sm-12 col-md-5 col-lg-5">
            <div class="introduction__image">
              <div id="hero-header-anim" />
            </div>
          </div>
          <div class="col-sm-12 col-md-7 col-lg-7">
            <div class="introduction__copy">
              <h2 class="headline introduction__headline ng-binding">
                {section.headline}
              </h2>
              <div class="introduction__par ng-binding">
                <PortableText value={section.body} />
              </div>
              <a
                href="https://app.pogo-lt.org/"
                class="button button--primary button--medium introduction__button"
                title="Launch Pogo LT"
              >
                <span>Launch Pogo LT</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="pogo-generates">
          <h3 class="pogo-generates__headline subline">Pogo LT Generates:</h3>
          <ul class="pogo-generates__list">
            <li class="pogo-generates__list__item">
              <div class="pogo-generates__document">
                <div class="pogo-generates__document-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 26.6 33.3"
                    xml:space="preserve"
                  >
                    <title>An icon for a XLS spreadsheet document.</title>
                    <g
                      stroke="#82342f"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    >
                      <path
                        fill="#fff"
                        d="M25.8 7.6v22.9c0 1.1-.9 2.1-2.1 2.1h-21c-1.1 0-2.1-.9-2.1-2.1V2.8C.6 1.7 1.5.7 2.7.7h17.6c.7 0 1.3.3 1.7.9l1.4 1.9 2 2.8c.1.1.1.2.2.4.2.3.2.6.2.9z"
                      />
                      <path
                        fill="none"
                        d="M25.6 7.9h-4.2c-.6 0-1.2-.5-1.2-1.2v-6"
                      />
                    </g>
                    <path
                      d="M5 18.1h1.1v1.1H5v-1.1zM6.9 18.7l1.5-2.1-1.4-2v-.5h1l1.2 1.7 1.1-1.7h.9v.5l-1.4 2 1.5 2.1v.5h-1L9 17.4l-1.2 1.8h-.9v-.5zM12.2 14.1h1.1v4.2h2.1v1h-3.2v-5.2zM16 18.3v-.5h.9c.1.4.5.6 1 .6s.8-.2.8-.6c0-.3-.2-.5-.8-.7h-.4c-.9-.2-1.5-.7-1.5-1.5 0-1 .7-1.6 1.9-1.6 1 0 1.6.4 1.8 1v.5h-.9c-.1-.4-.3-.6-.9-.6-.5 0-.7.2-.7.6 0 .3.2.5.7.6l.5.1c.9.2 1.5.7 1.5 1.6 0 1-.8 1.6-1.9 1.6-1.1 0-1.7-.5-2-1.1z"
                      style="fill:#82342f"
                    />
                  </svg>
                </div>
                <p>An easy-to-read spreadsheet</p>
              </div>
            </li>
            <li class="pogo-generates__list__item">
              <div class="pogo-generates__document">
                <div class="pogo-generates__document-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 26.6 33.3"
                    xml:space="preserve"
                  >
                    <title>An icon for a PDF document</title>
                    <g
                      stroke="#82342f"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    >
                      <path
                        fill="#fff"
                        d="M25.8 7.6v22.9c0 1.1-.9 2.1-2.1 2.1h-21c-1.1 0-2.1-.9-2.1-2.1V2.8C.6 1.7 1.5.7 2.7.7h17.6c.7 0 1.3.3 1.7.9l1.4 1.9 2 2.8c.1.1.1.2.2.4.2.3.2.6.2.9z"
                      />
                      <path
                        fill="none"
                        d="M25.6 7.9h-4.2c-.6 0-1.2-.5-1.2-1.2v-6"
                      />
                    </g>
                    <path
                      d="M4.9 18.1H6v1.1H4.9v-1.1zM7.2 14.1h1.7c1.2 0 2 .7 2 1.7 0 .9-.7 1.8-2 1.8h-.6v1.7H7.2v-5.2zm1.6 2.5c.5 0 .9-.3.9-.8 0-.4-.3-.8-.9-.8h-.5v1.6h.5zM11.8 14.1h1.8c1.6 0 2.6 1.2 2.6 2.6s-1 2.6-2.6 2.6h-1.8v-5.2zm1.8 4.2c.9 0 1.4-.7 1.4-1.6 0-.9-.5-1.6-1.4-1.6h-.7v3.2h.7zM17.2 14.1h3.2v1h-2v1.2h1.8v.8h-1.8v2.1h-1.1v-5.1h-.1z"
                      style="fill:#82342f"
                    />
                  </svg>
                </div>
                <p>A PDF report</p>
              </div>
            </li>
            <li class="pogo-generates__list__item">
              <div class="pogo-generates__document">
                <div class="pogo-generates__document-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    x="0"
                    y="0"
                    version="1.1"
                    viewBox="0 0 26.6 33.3"
                    xml:space="preserve"
                  >
                    <title>An icon for a data file.</title>
                    <g
                      stroke="#82342f"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    >
                      <path
                        fill="#fff"
                        d="M25.8 7.6v22.9c0 1.1-.9 2.1-2.1 2.1h-21c-1.1 0-2.1-.9-2.1-2.1V2.8C.6 1.7 1.5.7 2.7.7h17.6c.7 0 1.3.3 1.7.9l1.4 1.9 2 2.8c.1.1.1.2.2.4.2.3.2.6.2.9z"
                      />
                      <path
                        fill="none"
                        d="M25.6 7.9h-4.2c-.6 0-1.2-.5-1.2-1.2v-6"
                      />
                    </g>
                    <path
                      d="M20.1 20.9c0 1.2-2.9 2.3-6.6 2.3s-6.6-1-6.6-2.3v-2.1c0-1.2 2.9-2.3 6.6-2.3s6.6 1 6.6 2.3v2.1z"
                      style="fill:#fff"
                    />
                    <path
                      d="M20.1 20.9c0 1.2-2.9 2.3-6.6 2.3s-6.6-1-6.6-2.3"
                      style="fill:#fff"
                    />
                    <path
                      d="M13.5 16.2c-3.4 0-7 .9-7 2.6v2.1c0 1.7 3.6 2.6 7 2.6s7-.9 7-2.6v-2.1c0-1.7-3.6-2.6-7-2.6zm0 6.6c-3.8 0-6.2-1.1-6.2-1.9v-2.1c0-.8 2.4-1.9 6.2-1.9 3.9 0 6.3 1.1 6.2 1.9v2.1c0 .8-2.4 1.9-6.2 1.9z"
                      style="fill:#82342f"
                    />
                    <ellipse
                      cx="13.5"
                      cy="18.8"
                      style="fill:#fff"
                      rx="6.6"
                      ry="2.3"
                    />
                    <path
                      d="M13.5 21.4c-3.4 0-7-.9-7-2.6s3.6-2.6 7-2.6 7 .9 7 2.6-3.6 2.6-7 2.6zm0-4.5c-3.8 0-6.2 1.1-6.2 1.9s2.4 1.9 6.2 1.9 6.2-1.1 6.2-1.9c.1-.8-2.3-1.9-6.2-1.9z"
                      style="fill:#82342f"
                    />
                    <g>
                      <path
                        d="M20.1 18.9c0 1.2-2.9 2.3-6.6 2.3s-6.6-1-6.6-2.3v-2.1c0-1.2 2.9-2.3 6.6-2.3s6.6 1 6.6 2.3v2.1z"
                        style="fill:#fff"
                      />
                      <path
                        d="M13.5 21.5c-3.4 0-7-.9-7-2.6v-2.1c0-1.7 3.6-2.6 7-2.6s7 .9 7 2.6v2.1c0 1.7-3.6 2.6-7 2.6zm0-6.6c-3.8 0-6.2 1.1-6.2 1.9v2.1c0 .8 2.4 1.9 6.2 1.9s6.2-1.1 6.2-1.9v-2.1c.1-.8-2.3-1.9-6.2-1.9z"
                        style="fill:#82342f"
                      />
                      <g>
                        <path
                          d="M13.5 21.5c-3.4 0-7-.9-7-2.6h.8c0 .8 2.4 1.9 6.2 1.9s6.2-1.1 6.2-1.9h.8c0 1.7-3.6 2.6-7 2.6z"
                          style="fill:#82342f"
                        />
                        <ellipse
                          cx="13.5"
                          cy="16.8"
                          style="fill:#fff"
                          rx="6.6"
                          ry="2.3"
                        />
                        <path
                          d="M13.5 19.4c-3.4 0-7-.9-7-2.6s3.6-2.6 7-2.6 7 .9 7 2.6-3.6 2.6-7 2.6zm0-4.5c-3.8 0-6.2 1.1-6.2 1.9s2.4 1.9 6.2 1.9 6.2-1.1 6.2-1.9-2.3-1.9-6.2-1.9z"
                          style="fill:#82342f"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        d="M20.1 16.6c0 1.2-2.9 2.3-6.6 2.3s-6.6-1-6.6-2.3v-2.1c0-1.2 2.9-2.3 6.6-2.3s6.6 1 6.6 2.3v2.1z"
                        style="fill:#fff"
                      />
                      <path
                        d="M13.5 19.2c-3.4 0-7-.9-7-2.6v-2.1c0-1.7 3.6-2.6 7-2.6s7 .9 7 2.6v2.1c0 1.7-3.6 2.6-7 2.6zm0-6.6c-3.8 0-6.2 1.1-6.2 1.9v2.1c0 .8 2.4 1.9 6.2 1.9s6.2-1.1 6.2-1.9v-2.1c.1-.8-2.3-1.9-6.2-1.9z"
                        style="fill:#82342f"
                      />
                    </g>
                    <g>
                      <path
                        d="M13.5 19.3c-3.4 0-7-.9-7-2.6h.8c0 .8 2.4 1.9 6.2 1.9s6.2-1.1 6.2-1.9h.8c0 1.7-3.6 2.6-7 2.6z"
                        style="fill:#82342f"
                      />
                      <ellipse
                        cx="13.5"
                        cy="14.5"
                        style="fill:#fff"
                        rx="6.6"
                        ry="2.3"
                      />
                      <path
                        d="M13.5 17.1c-3.4 0-7-.9-7-2.6s3.6-2.6 7-2.6 7 .9 7 2.6-3.6 2.6-7 2.6zm0-4.5c-3.8 0-6.2 1.1-6.2 1.9 0 .8 2.4 1.9 6.2 1.9s6.2-1.1 6.2-1.9c.1-.8-2.3-1.9-6.2-1.9z"
                        style="fill:#82342f"
                      />
                    </g>
                  </svg>
                </div>
                <p>An optional raw data file</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.introduction {
  margin-right: auto;
  margin-left: auto;
}

.introduction .introduction__copy {
  position: relative;
  z-index: 1;
  max-width: 50ch;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .introduction .introduction__copy {
    max-width: 26rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 0.5rem;
  }
}

@media only screen and (min-width: 961px) {
  .introduction .introduction__copy {
    max-width: 32rem;
    min-height: 31rem;
  }
}

.introduction .introduction__copy .introduction__headline {
  max-width: 26.25rem;
  font-size: var(--h2);
  line-height: var(--headline-line-height);
  font-family: var(--secondary-font-family);
}

@media only screen and (min-width: 358px) {
  .introduction .introduction__copy .introduction__headline {
    font-size: 1.75rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 358px) and (min-width: 961px) {
  .introduction .introduction__copy .introduction__headline {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .introduction .introduction__copy .introduction__headline {
    max-width: 28rem;
    min-width: 26rem;
  }
}

@media only screen and (min-width: 961px) {
  .introduction .introduction__copy .introduction__par {
    max-width: 90%;
  }
}

@media only screen and (min-width: 961px) {
  .introduction .introduction__copy {
    margin-top: -0.5rem;
  }
}

.introduction .introduction__image {
  margin-bottom: 1rem;
  width: 70%;
  max-width: 30rem;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 75%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .introduction .introduction__image {
    position: relative;
    width: 110%;
    margin-left: auto;
    margin-right: auto;
    max-width: none;
    padding-bottom: 144%;
    top: -2rem;
    right: 0;
    left: -7%;
  }
}

@media only screen and (min-width: 961px) {
  .introduction .introduction__image {
    position: relative;
    width: 100%;
    bottom: 0;
    padding-bottom: 118%;
    min-width: 0;
    margin-top: -4rem;
    left: auto;
    right: 0;
    top: 1rem;
    left: -8%;
    max-width: 31rem;
  }
}

@media only screen and (min-width: 2000px) {
  .introduction .introduction__image {
    left: -18%;
  }
}

.introduction .introduction__image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  margin: auto;
}

.introduction .introduction__button {
  max-width: 11rem;
  width: 100%;
}
.pogo-generates {
  margin-bottom: 1.5rem;
  max-width: 50ch;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .pogo-generates {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    max-width: 100%;
  }
}

@media only screen and (min-width: 961px) {
  .pogo-generates {
    width: 92%;
    max-width: 69rem;
  }
}

.pogo-generates .pogo-generates__headline {
  text-transform: uppercase;
  color: var(--primary-color);
  font-family: var(--secondary-font-family);
  font-size: var(---text-micro);
  line-height: var(--body-line-height);
}

@media only screen and (min-width: 961px) {
  .pogo-generates .pogo-generates__headline {
    font-size: var(--h4);
  }
}

.pogo-generates .pogo-generates__list {
  list-style: none;
  padding-left: 0;
}

@media only screen and (min-width: 768px) {
  .pogo-generates .pogo-generates__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.pogo-generates .pogo-generates__list__item {
  margin-bottom: 0.75rem;
}

@media only screen and (min-width: 768px) {
  .pogo-generates .pogo-generates__list__item {
    margin-right: 1.125rem;
  }
}

.pogo-generates .pogo-generates__document {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--secondary-font-family);
  text-transform: uppercase;
  font-weight: normal;
  color: var(--primary-color);
  font-size: var(--h4);
  line-height: var(--body-line-height);
}

@media only screen and (min-width: 961px) {
  .pogo-generates .pogo-generates__document {
    font-size: var(--text-normal);
    line-height: var(--body-line-height);
  }
}

.pogo-generates .pogo-generates__document .pogo-generates__document-icon {
  width: 1.875rem;
  height: auto;
  margin-right: 0.75rem;
}

@media only screen and (min-width: 768px) {
  .pogo-generates .pogo-generates__document .pogo-generates__document-icon {
    width: 2.45rem;
  }
}

.pogo-generates .pogo-generates__document .pogo-generates__document-icon svg {
  width: 100%;
  height: 100%;
}

.pogo-generates .pogo-generates__document p {
  margin-bottom: 0.375rem;
}
</style>
