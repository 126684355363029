<script>
import { PortableText } from "@portabletext/svelte";
import internalLink from "$lib/components/custom-marks/internal-link.svelte";
import externalLink from "$lib/components/custom-marks/external-link.svelte";
import mailtoLink from "$lib/components/custom-marks/mailto-link.svelte";
import { onMount } from "svelte";

export let section;
let ScrollTrigger;
let gsap;
let lottie;
let container;

onMount(async () => {
  const loadModules = async () => {
    // Dynamically import GSAP and its plugins
    const module = await import("gsap");
    gsap = module.gsap || module.default; // For environments with tree-shaking

    const scrollTriggerModule = await import("gsap/ScrollTrigger");
    ScrollTrigger =
      scrollTriggerModule.ScrollTrigger || scrollTriggerModule.default;

    const scrollToPluginModule = await import("gsap/ScrollToPlugin");
    const ScrollToPlugin =
      scrollToPluginModule.ScrollToPlugin || scrollToPluginModule.default;

    lottie = await import("lottie-web");

    // Register GSAP plugins
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  };

  await loadModules();

  // const { Power2 } = gsap;

  const sectionAnims = {
    section: container,
    selectors: {
      needs: '[data-ui-component="needs"]',
      needsCopy: '[data-ui-component="needs-copy"]',
      need: '[data-ui-component="need"]',
      needImages: '[data-ui-component="need-images"]',
      timeline: '[data-ui-component="timeline"]',
      counterButtons: '[data-ui-component="counter-button"]',
    },
    animationDuration: "200%",
    initDesktop: function () {
      this.loadAnimJSON().then((pJsonData) => {
        this.setUpLottieAnims(pJsonData);
        this.initScrollMagic();
      });
    },
    loadAnimJSON: async () => {
      const jsonData = [
        {
          name: "phone-logger-anim",
          url: "https://cdn.sanity.io/files/ebeyjpve/production/2d6894a73f7db15b3f286898f83038eaf93d09c2.json",
        },
        {
          name: "varo-report-anim",
          url: "https://cdn.sanity.io/files/ebeyjpve/production/bf1d038c20c2d74e0155affe8469c8f0adb1da6d.json",
        },
        {
          name: "email-anim",
          url: "https://cdn.sanity.io/files/ebeyjpve/production/60c6df7c01bc29f019c05057cee8773021b6b24f.json",
        },
        {
          name: "varo-reports-anim",
          url: "https://cdn.sanity.io/files/ebeyjpve/production/a147e7beeb02887d9cd2c998986472059d4cd0ae.json",
        },
        {
          name: "laptop-anim",
          url: "https://cdn.sanity.io/files/ebeyjpve/production/58ab064201d58c6eec391314994e04b95722d45e.json",
        },
      ];

      return Promise.all(
        jsonData.map(async (e) => {
          try {
            const response = await fetch(e.url);
            return await response.json();
          } catch (err) {
            console.log(err);
          }
        })
      );
    },
    setUpLottieAnims: function (pLottieData) {
      let jsonData = pLottieData;
      let section = this.section;

      const needImages = section.querySelectorAll(this.selectors.needImages);
      needImages.forEach((elem) => elem.classList.add("is-animated"));

      let animations = [
        { data: jsonData[0], id: "phone-logger-anim", speed: 1.1 },
        { data: jsonData[1], id: "varo-report-anim", speed: 1.0 },
        { data: jsonData[2], id: "email-anim", speed: 1.2 },
        { data: jsonData[3], id: "varo-reports-anim", speed: 1.0 },
        { data: jsonData[4], id: "internet-anim", speed: 1.5 },
      ];

      this.lottieAnimations = animations.map(({ data, id, speed }) => {
        const animProps = {
          container: section.querySelector(`#${id}`),
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: data,
          rendererSettings: { progressiveLoad: true },
        };
        const anim = lottie.loadAnimation(animProps);
        anim.setSpeed(speed);
        return anim;
      });
    },
    initScrollMagic: function () {
      let that = this;
      const [phoneLoggerLottieAnim, , emailLottieAnim, , laptopLottieAnim] =
        this.lottieAnimations;

      const masterTimeLineTween = gsap.timeline({
        scrollTrigger: {
          trigger: this.selectors.needsCopy,
          start: "top 25%",
          end: () =>
            `+=${
              this.section.querySelector(this.selectors.needImages)
                .offsetHeight / 1.65
            }`,
          pin: true,
          scrub: 1,
          anticipatePin: 1,
          onUpdate: (self) => {
            if (self.progress === 0) {
              that.section
                .querySelectorAll(this.selectors.need)[0]
                .classList.remove("is-checked");
            }
          },
        },
      });

      masterTimeLineTween
        .call(() => phoneLoggerLottieAnim.play())
        .fromTo(
          this.selectors.timeline,
          { height: 0 },
          {
            height: ".05%",
            onComplete: () => {
              that.section
                .querySelectorAll(this.selectors.need)[0]
                .classList.add("is-checked");
            },
          }
        )
        .fromTo(
          this.selectors.timeline,
          { height: ".05%" },
          {
            height: "46%",
            duration: 0.8,
            onUpdate: () => {
              that.section
                .querySelectorAll(this.selectors.need)[1]
                .classList.remove("is-checked");
            },
            onComplete: () => {
              that.section
                .querySelectorAll(this.selectors.need)[1]
                .classList.add("is-checked");
            },
          },
          "-=0.1"
        )
        .fromTo(
          '[data-ui-component="varo-doc-travel-line"]',
          { opacity: 0 },
          { opacity: 1, duration: 0.1 },
          "-=0.7"
        )
        .fromTo(
          '[data-ui-component="varo-doc"]',
          { opacity: 0 },
          { opacity: 1, duration: 0.1 },
          "-=0.6"
        )
        .fromTo(
          '[data-ui-component="varo-doc"]',
          { y: 0 },
          { y: 230, duration: 0.6 },
          "-=0.6"
        )
        .to(
          this.section.querySelectorAll("[data-ui-component='need']")[1],
          { duration: 0.1 },
          "-=0.1"
        )
        .call(() => emailLottieAnim.play(), null, "-=0.1")
        .to(
          this.selectors.timeline,
          {
            height: "94%",
            duration: 0.7,
            onUpdate: () => {
              that.section
                .querySelectorAll(this.selectors.need)[2]
                .classList.remove("is-checked");
            },
            onComplete: () => {
              that.section
                .querySelectorAll(this.selectors.need)[2]
                .classList.add("is-checked");
            },
          },
          ">"
        )
        .fromTo(
          '[data-ui-component="varo-docs-travel-line"]',
          { opacity: 0 },
          { opacity: 1, duration: 0.1 },
          "-=0.7"
        )
        .fromTo(
          '[data-ui-component="varo-docs"]',
          { opacity: 0 },
          { opacity: 1, duration: 0.1 },
          "-=0.6"
        )
        .fromTo(
          '[data-ui-component="varo-docs"]',
          { y: 0 },
          { y: 244, duration: 0.6 },
          "-=0.6"
        )
        .call(() => laptopLottieAnim.play(), null, "-=0.18");

      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 1000);
    },
  };

  setTimeout(() => {
    sectionAnims.initDesktop();
  }, 200);
});
</script>

<section class="before-using-pogo" bind:this={container}>
  <div class="before-using-pogo__inner">
    <div class="container-fluid">
      <div class="row center-md">
        <div class="col-sm-12 col-lg-12">
          <div class="row" data-ui-component="needs">
            <div class="col-sm-12 col-md-6">
              <div
                class="before-using-pogo__copy"
                data-ui-component="needs-copy"
              >
                <div class="before-using-pogo__header">
                  <h2
                    class="before-using-pogo__header__headline headline ng-binding"
                  >
                    {section.headline}
                  </h2>
                </div>
                <ul class="before-using-pogo__needs">
                  <div class="before-using-pogo__needs__timeline">
                    <div data-ui-component="timeline" />
                  </div>

                  {#each section.list as step, index}
                    <li
                      class="before-using-pogo__need"
                      data-ui-component="need"
                    >
                      <div
                        class="before-using-pogo__need__image hide-for-medium-up"
                      >
                        <img
                          srcset="
                            {step.imageUrl}?auto=format&width=600 600w,
                            {step.imageUrl}?auto=format&width=400 400w,
                            {step.imageUrl}?auto=format&width=300 300w"
                          sizes="(min-width: 961px) 800px, (min-width: 768px) 50vw - 4rem, calc(100vw - 6rem)"
                          src="{step.imageUrl}?auto=format&width=100"
                          alt={step.headline}
                          class="secure-section__image__background"
                          aria-hidden="true"
                          loading="lazy"
                          role="presentation"
                          width="500"
                          height="100"
                        />
                      </div>
                      <div class="before-using-pogo__need__description">
                        <button
                          aria-label="Scroll to step {step.animation_id} on using Pogo LT needs"
                          class="before-using-pogo__need__counter"
                          data-ui-component="counter-button"
                          data-id={step.animation_id}
                        />
                        <div>
                          <PortableText
                            value={step.body}
                            components={{
                              marks: {
                                internalLink: internalLink,
                                externalLink: externalLink,
                                mailtoLink: mailtoLink,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  {/each}
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 show-for-medium-up">
              <div
                class="before-using-pogo__images is-animated"
                data-ui-component="need-images"
              >
                <div class="before-using-pogo__images__inner">
                  <div
                    id="phone-logger-anim"
                    class="before-using-pogo__images__phone-logger-anim"
                  />
                  <div class="before-using-pogo__images__varo-report-anim">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 297 319.1"
                      xml:space="preserve"
                      role="presentation"
                    >
                      <g data-ui-component="varo-doc-travel-line">
                        <path
                          style="  fill: none;
                        stroke: #ccc;
                        stroke-width: 4.3235;
                        stroke-linecap: round;
                        stroke-linejoin: round;"
                          d="M147.6 74.2"
                        />
                        <path
                          fill="none"
                          stroke="#ccc"
                          stroke-width="4.3235"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-dasharray=".1025,18.4517"
                          d="M147.6 92.7v119.2"
                        />
                        <path class="st0" d="M147.6 221.1" />
                      </g>

                      <g data-ui-component="varo-doc">
                        <g>
                          <path
                            style="     fill: #fff;
                            stroke: #09638a;
                            stroke-width: 3;
                            stroke-miterlimit: 10;"
                            d="M170.8 28.3v43.4c0 2-1.6 3.8-3.8 3.8h-37.5c-2 0-3.8-1.6-3.8-3.8v-52c0-2 1.6-3.8 3.8-3.8H161c1.3 0 2.3.5 3 1.6l2.5 3.4 3.6 5c.2.2.2.4.4.7.3.6.3 1.1.3 1.7z"
                          />
                          <path
                            style="     fill: #fff;
                            stroke: #09638a;
                            stroke-width: 3;
                            stroke-miterlimit: 10;"
                            d="M170.4 28.8h-7.5c-1.1 0-2.1-.9-2.1-2.1V15.9"
                          />
                          <path
                            d="M147.2 32.9c8.3.2 14.2 6.1 14.4 14.4 0 .2-.2.4-.4.4h-2.8c-.2 0-.3-.2-.3-.3-.2-5.9-5-10.7-10.9-10.9-.2 0-.3-.2-.3-.3v-2.8c0-.3.1-.5.3-.5z"
                            fill="#00abe8"
                          />
                          <path
                            d="M147.1 43.6c-.1 0-.2.1-.2.2v3.6c0 .1.1.3.3.3h3.5c.1 0 .2-.1.2-.2-.1-2.1-1.7-3.8-3.8-3.9z"
                            fill="#28aae1"
                          />
                          <path
                            d="M147.4 37.9c5.2 0 9.4 4.3 9.4 9.3 0 5.1-4.2 9.3-9.4 9.3s-9.4-4.3-9.4-9.3c0-5.1 4.2-9.3 9.4-9.3zm0 13.8c2.4 0 4.4-2 4.4-4.6 0-2.5-2-4.5-4.4-4.5-2.5 0-4.4 2-4.4 4.5 0 2.6 1.9 4.6 4.4 4.6z"
                            fill="#09638a"
                          />
                        </g>
                      </g></svg
                    >
                  </div>

                  <div
                    id="email-anim"
                    class="before-using-pogo__images__email-anim"
                  />
                  <div
                    class="before-using-pogo__images__varo-reports-anim"
                    id="varo-reports-anim"
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      viewBox="0 0 297 326.1"
                      xml:space="preserve"
                      role="presentation"
                    >
                      <g data-ui-component="varo-docs-travel-line">
                        <path
                          style="fill:none;stroke:#ccc;stroke-width:4.3235;stroke-linecap:round;stroke-linejoin:round"
                          d="M147.6 74.2"
                        />
                        <path
                          fill="none"
                          stroke="#ccc"
                          stroke-width="4.3235"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-dasharray=".1025,18.4517"
                          d="M147.6 92.7v119.2"
                        />
                      </g>

                      <g data-ui-component="varo-docs">
                        <path
                          style="fill:none;stroke:#CCCCCC;stroke-width:4.3235;stroke-linecap:round;stroke-linejoin:round;"
                          d="M147.6 221.1"
                        />
                        <path
                          style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                          d="M159.7 23.1v43.4c0 2-1.6 3.8-3.8 3.8h-37.5c-2 0-3.8-1.6-3.8-3.8v-52c0-2 1.6-3.8 3.8-3.8h31.5c1.3 0 2.3.5 3 1.6l2.5 3.4 3.6 5c.2.2.2.4.4.7.3.6.3 1.2.3 1.7z"
                        />
                        <path
                          d="M159.3 23.6h-7.5c-1.1 0-2.1-.9-2.1-2.1V10.7"
                          style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                        />
                        <g>
                          <path
                            style="fill: #00abe8"
                            d="M136.1 27.7c8.3.2 14.2 6.1 14.4 14.4 0 .2-.2.4-.4.4h-2.8c-.2 0-.3-.2-.3-.3-.2-5.9-5-10.7-10.9-10.9-.2 0-.3-.2-.3-.3v-2.8c0-.3.1-.5.3-.5z"
                          />
                          <path
                            style="fill: #28aae1;"
                            d="M136 38.4c-.1 0-.2.1-.2.2v3.6c0 .1.1.3.3.3h3.5c.1 0 .2-.1.2-.2-.1-2.1-1.7-3.8-3.8-3.9z"
                          />
                          <path
                            style="fill: #09638a;"
                            d="M136.3 32.7c5.2 0 9.4 4.3 9.4 9.3 0 5.1-4.2 9.3-9.4 9.3s-9.4-4.3-9.4-9.3c0-5.1 4.2-9.3 9.4-9.3zm0 13.8c2.4 0 4.4-2 4.4-4.6 0-2.5-2-4.5-4.4-4.5-2.5 0-4.4 2-4.4 4.5 0 2.6 1.9 4.6 4.4 4.6z"
                          />
                        </g>
                        <g>
                          <path
                            style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                            d="M173.6 29.3v43.4c0 2-1.6 3.8-3.8 3.8h-37.5c-2 0-3.8-1.6-3.8-3.8v-52c0-2 1.6-3.8 3.8-3.8h31.5c1.3 0 2.3.5 3 1.6l2.5 3.4 3.6 5c.2.2.2.4.4.7.3.6.3 1.1.3 1.7z"
                          />
                          <path
                            style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                            d="M173.2 29.9h-7.5c-1.1 0-2.1-.9-2.1-2.1V17"
                          />
                          <g>
                            <path
                              style="fill: #00abe8"
                              d="M150 34c8.3.2 14.2 6.1 14.4 14.4 0 .2-.2.4-.4.4h-2.8c-.2 0-.3-.2-.3-.3-.2-5.9-5-10.7-10.9-10.9-.2 0-.3-.2-.3-.3v-2.8c0-.4.1-.5.3-.5z"
                            />
                            <path
                              style="fill: #28aae1;"
                              d="M149.9 44.7c-.1 0-.2.1-.2.2V55c0 .1.1.3.3.3h3.5c.1 0 .2-.1.2-.2-.1-2.2-1.7-3.8-3.8-3.9z"
                            />
                            <path
                              style="fill: #09638a;"
                              d="M150.2 38.9c5.2 0 9.4 4.3 9.4 9.3 0 5.1-4.2 9.3-9.4 9.3s-9.4-4.3-9.4-9.3c-.1-5.1 4.1-9.3 9.4-9.3zm0 13.8c2.4 0 4.4-2 4.4-4.6 0-2.5-2-4.5-4.4-4.5-2.5 0-4.4 2-4.4 4.5-.1 2.6 1.9 4.6 4.4 4.6z"
                            />
                          </g>
                        </g>
                        <g>
                          <path
                            style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                            d="M188.8 35.9v43.4c0 2-1.6 3.8-3.8 3.8h-37.5c-2 0-3.8-1.6-3.8-3.8v-52c0-2 1.6-3.8 3.8-3.8H179c1.3 0 2.3.5 3 1.6l2.5 3.4 3.6 5c.2.2.2.4.4.7.3.6.3 1.1.3 1.7z"
                          />
                          <path
                            style="fill:#FFFFFF;stroke:#09638A;stroke-width:3;stroke-miterlimit:10;"
                            d="M188.5 36.4H181c-1.1 0-2.1-.9-2.1-2.1V23.5"
                          />
                          <g>
                            <path
                              style="fill: #00abe8"
                              d="M165.3 40.5c8.3.2 14.2 6.1 14.4 14.4 0 .2-.2.4-.4.4h-2.8c-.2 0-.3-.2-.3-.3-.2-5.9-5-10.7-10.9-10.9-.2 0-.3-.2-.3-.3V41c-.1-.3.1-.5.3-.5z"
                            />
                            <path
                              style="fill: #28aae1;"
                              d="M165.2 51.2c-.1 0-.2.1-.2.2V55c0 .1.1.3.3.3h3.5c.1 0 .2-.1.2-.2-.1-2.1-1.8-3.8-3.8-3.9z"
                            />
                            <path
                              style="fill: #09638a;"
                              d="M165.5 45.5c5.2 0 9.4 4.3 9.4 9.3 0 5.1-4.2 9.3-9.4 9.3s-9.4-4.3-9.4-9.3c-.1-5.1 4.1-9.3 9.4-9.3zm0 13.8c2.4 0 4.4-2 4.4-4.6 0-2.5-2-4.5-4.4-4.5-2.5 0-4.4 2-4.4 4.5-.1 2.6 1.9 4.6 4.4 4.6z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div
                    id="internet-anim"
                    class="before-using-pogo__images__laptop-anim"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
/*------------------------------------*\
      #Before Using Pogo
  \*------------------------------------*/

.before-using-pogo__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 64rem;
}

/*------------------------------------*\
      #Header
  \*------------------------------------*/

.before-using-pogo__header {
  margin-bottom: 2.25rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 24.375rem;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .before-using-pogo {
    margin-bottom: 3.25rem;
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo {
    margin-bottom: 1.5rem;
  }
}

.before-using-pogo .before-using-pogo__header {
  margin-bottom: 2.25rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 24.375rem;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .before-using-pogo .before-using-pogo__header {
    max-width: 100%;
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo .before-using-pogo__header {
    margin-bottom: 2.25rem;
  }
}

.before-using-pogo__header .before-using-pogo__header__headline {
  font-family: var(--secondary-font-family);
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: center;
}

@media only screen and (min-width: 358px) {
  .before-using-pogo__header .before-using-pogo__header__headline {
    font-size: 1.75rem;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 358px) and (min-width: 961px) {
  .before-using-pogo__header .before-using-pogo__header__headline {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo__header .before-using-pogo__header__headline {
    font-size: 1.5rem;
    line-height: 1.2;
    max-width: 16.875rem;
    text-align: left;
  }
}

.before-using-pogo__copy {
  margin-left: auto;
  margin-right: auto;
  max-width: 34.375rem;
  position: relative;
}

/*------------------------------------*\
      #Pogo Requirements/Needs
  \*------------------------------------*/

.before-using-pogo__needs {
  position: relative;
}

.before-using-pogo__needs .before-using-pogo__needs__timeline {
  background-color: #f2f2f2;
  bottom: 0;
  display: none;
  height: 74%;
  left: 1rem;
  position: absolute;
  top: 0;
  width: 3px;
  z-index: 0;
}

@media only screen and (min-width: 768px) {
  .before-using-pogo
    .before-using-pogo__needs
    .before-using-pogo__needs__timeline {
    display: block;
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__needs
    .before-using-pogo__needs__timeline {
    height: 73%;
  }
}

.before-using-pogo
  .before-using-pogo__needs
  .before-using-pogo__needs__timeline
  div {
  background-color: #82342f;
  height: 0;
  width: 100%;
}

.before-using-pogo .before-using-pogo__need {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2.25rem;
  position: relative;
}

@media only screen and (min-width: 358px) {
  .before-using-pogo .before-using-pogo__need {
    margin-bottom: 3.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo .before-using-pogo__need {
    align-items: flex-start;
    height: 7.25rem;
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo .before-using-pogo__need {
    height: 30vh;
    margin-bottom: 0;
    max-height: 10rem;
  }
}

.before-using-pogo
  .before-using-pogo__need.is-checked
  .before-using-pogo__need__description {
  color: #4d2319;
}

.before-using-pogo
  .before-using-pogo__need.is-checked
  .before-using-pogo__need__counter {
  border-color: #82342f;
}

.before-using-pogo
  .before-using-pogo__need.is-checked
  .before-using-pogo__need__counter:after {
  transform: scale(1);
}

:global(.before-using-pogo .before-using-pogo__need.is-checked em) {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.2s ease-in-out 0.1s;
}

.before-using-pogo .before-using-pogo__need .before-using-pogo__need__image {
  margin-right: 1rem;
  width: 29.5%;
}

@media only screen and (min-width: 358px) {
  .before-using-pogo .before-using-pogo__need .before-using-pogo__need__image {
    margin-right: 1.5rem;
    width: 33%;
  }
}

.before-using-pogo
  .before-using-pogo__need
  .before-using-pogo__need__description {
  font-size: 0.9rem;
  line-height: 1.5;
  max-width: 17rem;
  transition: all 0.2s ease-in-out 0.1s;
  width: 70%;
}

@media only screen and (min-width: 358px) {
  .before-using-pogo
    .before-using-pogo__need
    .before-using-pogo__need__description {
    font-size: 1rem;
    font-size: var(--text-normal);
    line-height: 1.5;
    width: 64.5%;
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo
    .before-using-pogo__need
    .before-using-pogo__need__description {
    align-items: top;
    display: flex;
    font-size: var(--h5);
    line-height: var(--headline-line-height);
    max-width: 85%;
    width: 100%;
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__need
    .before-using-pogo__need__description {
    font-size: var(--h4);
  }
}

:global(.before-using-pogo__need__description p) {
  margin-bottom: 0.375rem;
}

:global(.before-using-pogo__need__description em) {
  align-items: center;
  color: #82342f;
  display: flex;
  display: inline;
  font-family: var(--secondary-font-family);
  font-size: var(--text-mini);
  font-style: normal;
  line-height: 1.5;
  line-height: var(--headline-line-height);
  text-transform: uppercase;
}

@media only screen and (min-width: 358px) {
  :global(.before-using-pogo__need__description em) {
    font-size: var(--text-micro);
  }
}

.before-using-pogo .before-using-pogo__need__counter {
  background-color: #fff;
  border: 3px solid #d6c0c0;
  border-radius: 100%;
  display: none;
  flex: none;
  height: 2rem;
  margin-right: 1rem;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 2rem;
  z-index: 1;
}

@media only screen and (min-width: 768px) {
  .before-using-pogo .before-using-pogo__need__counter {
    display: block;
  }

  .before-using-pogo .before-using-pogo__need__counter:after {
    background-color: #898f3c;
    border-radius: 100%;
    bottom: 0;
    content: " ";
    height: 84%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
    width: 84%;
  }
}

/*------------------------------------*\
      #Images/Animation
  \*------------------------------------*/

@media only screen and (min-width: 768px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    max-width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo
    .before-using-pogo__images
    .before-using-pogo__images__varo-report-anim,
  .before-using-pogo
    .before-using-pogo__images
    .before-using-pogo__images__varo-reports-anim {
    opacity: 0;
    z-index: 0;
  }
}

:global(
    .before-using-pogo__images.is-animated .before-using-pogo__images__inner
  ) {
  height: 90rem;
  overflow: hidden;
}
:global(.before-using-pogo .before-using-pogo__images.is-animated) {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-top: 4rem;
}

@media only screen and (min-width: 961px) {
  :global(.before-using-pogo .before-using-pogo__images.is-animated) {
    width: 92%;
  }
}

:global(.before-using-pogo .before-using-pogo__images.is-animated div) {
  position: relative;
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__phone-logger-anim {
  z-index: 1;
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-report-anim,
.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-reports-anim {
  margin-left: auto;
  margin-right: auto;
  /* margin-top: -5rem; */
  max-width: 85%;
  z-index: 0;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-report-anim,
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-reports-anim {
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-report-anim {
  opacity: 1;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-report-anim {
    /* margin-top: -10rem; */
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-reports-anim {
  /* margin-top: -7rem; */
  opacity: 1;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-reports-anim {
    /* margin-top: -13rem; */
    margin-bottom: -28rem;
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__email-anim {
  /* margin-top: -6rem; */
  z-index: 1;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__email-anim {
    /* margin-top: -11rem; */
  }
}

:global(
    .before-using-pogo
      .before-using-pogo__images.is-animated
      .before-using-pogo__images__laptop-anim
  ) {
  /* margin-top: -6rem; */
  z-index: 1;
}

@media only screen and (min-width: 961px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images.is-animated
        .before-using-pogo__images__laptop-anim
    ) {
    /* margin-top: -11rem; */
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo
    .before-using-pogo__need
    .before-using-pogo__need__description {
    max-width: 85%;
    width: 100%;
    display: flex;
    align-items: top;
    font-size: var(--h5);
    line-height: var(--headline-line-height);
  }
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__need
    .before-using-pogo__need__description {
    font-size: var(--h4);
  }
}

:global(.before-using-pogo__need__description p) {
  margin-bottom: 0.375rem;
}

:global(.before-using-pogo__need__description em) {
  text-transform: uppercase;
  color: #82342f;
  display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: var(--text-mini);
  font-family: var(--secondary-font-family);
  display: inline;
  line-height: var(--headline-line-height);
  font-style: normal;
}

@media only screen and (min-width: 358px) {
  :global(.before-using-pogo__need__description em) {
    font-size: var(--text-micro);
  }
}

@media only screen and (min-width: 768px) {
  :global(.before-using-pogo .before-using-pogo__need em) {
  }
}

.before-using-pogo .before-using-pogo__need__counter {
  border-radius: 100%;
  border: 3px solid #d6c0c0;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  flex: none;
  z-index: 1;
  background-color: #fff;
  display: none;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .before-using-pogo .before-using-pogo__need__counter {
    display: block;
  }

  .before-using-pogo .before-using-pogo__need__counter:after {
    content: " ";
    width: 84%;
    height: 84%;
    position: absolute;
    background-color: #898f3c;
    border-radius: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }
}

/*------------------------------------*\
      #Images/Animation
  \*------------------------------------*/

@media only screen and (min-width: 768px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) and (min-width: 768px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    max-width: 90%;
  }
}

@media only screen and (min-width: 768px) and (min-width: 961px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images
        .before-using-pogo__images__image
    ) {
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .before-using-pogo
    .before-using-pogo__images
    .before-using-pogo__images__varo-report-anim,
  .before-using-pogo
    .before-using-pogo__images
    .before-using-pogo__images__varo-reports-anim {
    z-index: 0;
    opacity: 0;
  }
}

:global(.before-using-pogo .before-using-pogo__images.is-animated) {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
}

@media only screen and (min-width: 961px) {
  :global(.before-using-pogo .before-using-pogo__images.is-animated) {
    width: 92%;
  }
}

:global(.before-using-pogo .before-using-pogo__images.is-animated div) {
  position: relative;
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__phone-logger-anim {
  z-index: 1;
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-report-anim,
.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-reports-anim {
  z-index: 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 85%;
  position: relative;
  top: -8rem;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-report-anim,
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-reports-anim {
    /* margin-top: -9.5rem; */
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-report-anim {
  opacity: 1;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-report-anim {
    /* margin-top: -10rem; */
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__varo-reports-anim {
  /* margin-top: -7rem; */
  opacity: 1;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__varo-reports-anim {
    position: relative;
    top: -30rem;
  }
}

.before-using-pogo
  .before-using-pogo__images.is-animated
  .before-using-pogo__images__email-anim {
  z-index: 1;
  position: relative;
  top: -18rem;
}

@media only screen and (min-width: 961px) {
  .before-using-pogo
    .before-using-pogo__images.is-animated
    .before-using-pogo__images__email-anim {
    /* margin-top: -11rem; */
  }
}

:global(
    .before-using-pogo
      .before-using-pogo__images.is-animated
      .before-using-pogo__images__laptop-anim
  ) {
  z-index: 1;
  position: relative;
  top: -29rem;
}

@media only screen and (min-width: 961px) {
  :global(
      .before-using-pogo
        .before-using-pogo__images.is-animated
        .before-using-pogo__images__laptop-anim
    ) {
    /* margin-top: -11rem; */
  }
}
</style>
