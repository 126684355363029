<script>
import { PortableText } from "@portabletext/svelte";
import { onMount } from "svelte";
export let section;
import ScrollMagic from "scrollmagic-with-ssr";

onMount(async () => {
  const lottie = await import("lottie-web");

  function loadAnimJSON() {
    let jsonData = [
      "https://cdn.sanity.io/files/ebeyjpve/production/0bfb1147c32cee89222ea973cfaf2a98c1a9e70c.json",
      "https://cdn.sanity.io/files/ebeyjpve/production/ea973b63c20d0023962985eefb3fd03690ccde9c.json",
      "https://cdn.sanity.io/files/ebeyjpve/production/684136e24c4aec528bfff7c7253fe8911006c03f.json",
      "https://cdn.sanity.io/files/ebeyjpve/production/b3868d06b05122c794237c9f8e327fd9e7789eeb.json",
    ];

    return Promise.all(
      jsonData.map((url) => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.responseType = "json";
          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve(xhr.response);
            } else {
              reject(new Error(`Failed to load: ${xhr.status}`));
            }
          };
          xhr.onerror = () => reject(new Error("Network error"));
          xhr.send();
        });
      })
    );
  }

  function initAnimations(pJSONdata) {
    const selectors = {
      varoBreezeAnimContainer: "varo-breeze",
      varoBreezeTrigger: '[data-ui-component="varo-breeze-scene"]',
      visibleColdChainAnimContainer: "visible-cold-chain",
      visibleColdChainTrigger: '[data-ui-component="visible-cold-chain-scene"]',
      performanceRecordsAnimContainer: "performance-records",
      performanceRecordsTrigger:
        '[data-ui-component="performance-records-scene"]',
      privacyFreeAnimContainer: "privacy-free",
      privacyFreeTrigger: '[data-ui-component="privacy-free"]',
    };

    const varoBreezeAnimData = pJSONdata[0];
    const visibleColdChainAnimData = pJSONdata[1];
    const performanceRecordsAnimData = pJSONdata[2];
    const privacyFreeAnimData = pJSONdata[3];

    const scrollController = new ScrollMagic.Controller({});

    const explainingAnimsProps = {
      varoBreeze: {
        container: document.getElementById(selectors.varoBreezeAnimContainer),
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: varoBreezeAnimData,
        rendererSettings: {
          progressiveLoad: true,
        },
      },
      visibleColdChain: {
        container: document.getElementById(
          selectors.visibleColdChainAnimContainer
        ),
        renderer: "svg",
        loop: true,
        autoplay: false,
        animationData: visibleColdChainAnimData,
        rendererSettings: {
          progressiveLoad: true,
        },
      },
      performanceRecords: {
        container: document.getElementById(
          selectors.performanceRecordsAnimContainer
        ),
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: performanceRecordsAnimData,
        rendererSettings: {
          progressiveLoad: true,
        },
      },
      privacyFree: {
        container: document.getElementById(selectors.privacyFreeAnimContainer),
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: privacyFreeAnimData,
        rendererSettings: {
          progressiveLoad: true,
        },
      },
    };

    const varoBreezeAnimation = lottie.loadAnimation(
      explainingAnimsProps.varoBreeze
    );
    varoBreezeAnimation.setSpeed(0.8);

    const visibleColdChainAnimation = lottie.loadAnimation(
      explainingAnimsProps.visibleColdChain
    );
    visibleColdChainAnimation.setSpeed(0.9);

    const performanceRecordsAnimation = lottie.loadAnimation(
      explainingAnimsProps.performanceRecords
    );
    performanceRecordsAnimation.setSpeed(0.9);

    const privacyFreeAnimation = lottie.loadAnimation(
      explainingAnimsProps.privacyFree
    );
    privacyFreeAnimation.setSpeed(3);

    var varoBreezeScene = new ScrollMagic.Scene({
      triggerElement: selectors.varoBreezeTrigger,
      triggerHook: 0.5,
    })
      .on("enter", function () {
        varoBreezeAnimation.play();
      })
      .addTo(scrollController);

    var visibleColdChainScene = new ScrollMagic.Scene({
      triggerElement: selectors.visibleColdChainTrigger,
      triggerHook: 0.6,
    })
      .on("enter", function () {
        visibleColdChainAnimation.play();
      })
      .addTo(scrollController);

    var performanceRecordsScene = new ScrollMagic.Scene({
      triggerElement: selectors.performanceRecordsTrigger,
      triggerHook: 0.6,
    })
      .on("enter", function () {
        performanceRecordsAnimation.playSegments([0, 100], true);
      })
      .addTo(scrollController);

    var privacyFreeScene = new ScrollMagic.Scene({
      triggerElement: selectors.privacyFreeTrigger,
      triggerHook: 0.6,
    })
      .on("enter", function () {
        var dir = 1;
        privacyFreeAnimation.play();
        privacyFreeAnimation.addEventListener("complete", function () {
          dir = dir * -1;
          privacyFreeAnimation.setDirection(dir);
          privacyFreeAnimation.play();
        });
      })
      .addTo(scrollController);
  }

  if (window.innerWidth > 767) {
    loadAnimJSON().then((response) => {
      let animJSONData = response;
      initAnimations(animJSONData);
    });
  } else {
    return;
  }
});
</script>

<div class="explaining-sections">
  {#each section.section as copy_section}
    {#if copy_section.section_type == "reports"}
      <section
        class="explaining-section easy-reports"
        data-ui-component="varo-breeze-scene"
      >
        <div class="container-fluid">
          <div class="row center-md">
            <div class="col-sm-12 col-md-11">
              <div class="row middle-md">
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__graphic-container">
                    <div
                      class="hide-for-medium-up easy-reports__graphic explaining-section__graphic"
                    >
                      <img
                        alt="Line art illustration of a folder filled with Varo 30DTR reports being aggregated into a single report."
                        loading="lazy"
                        src="https://cdn.sanity.io/images/ebeyjpve/production/fa60af937aeee6c0282393539f0ad46ea0629ac8-466x298.svg"
                        width="500"
                        height="250"
                      />
                    </div>
                    <div
                      class="show-for-medium-up easy-reports__graphic explaining-section__graphic"
                    >
                      <div id="varo-breeze" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__copy">
                    <h2
                      class="explaining-section__headline headline ng-binding"
                    >
                      {copy_section.headline}
                    </h2>
                    <div>
                      <PortableText value={copy_section.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/if}

    {#if copy_section.section_type == "visible"}
      <section
        class="explaining-section visible-section"
        data-ui-component="visible-cold-chain-scene"
      >
        <div class="container-fluid">
          <div class="row center-md">
            <div class="col-sm-12 col-md-11">
              <div class="row middle-md reverse-md">
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__graphic-container">
                    <div
                      class="hide-for-medium-up visible-section__graphic explaining-section__graphic"
                    >
                      <img
                        loading="lazy"
                        alt="Line art illustration of 4 cold chain fridges displaying their alarm rate, one has an alarm rate of over 97 found by reading the Pogo LT report."
                        src="https://cdn.sanity.io/images/ebeyjpve/production/4d74bbcd85c99f360e32d85c898d398c93125ba2-481x395.svg"
                        width="500"
                        height="250"
                      />
                    </div>
                    <div
                      class="show-for-medium-up visible-section__graphic explaining-section__graphic"
                    >
                      <div id="visible-cold-chain" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__copy">
                    <h2
                      class="explaining-section__headline headline ng-binding"
                    >
                      {copy_section.headline}
                    </h2>
                    <div class="explaining-section__body">
                      <PortableText value={copy_section.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/if}

    {#if copy_section.section_type == "performance"}
      <section
        class="explaining-section performance-section"
        data-ui-component="performance-records-scene"
      >
        <div class="container-fluid">
          <div class="row center-md">
            <div class="col-sm-12 col-md-11">
              <div class="row middle-md">
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__graphic-container">
                    <div
                      class="hide-for-medium-up performance-section__graphic explaining-section__graphic"
                    >
                      <img
                        alt="Line art illustration of a computer monitor displaying the Pogo LT user experience to choose a date range for the reports to aggregate."
                        loading="lazy"
                        src="https://cdn.sanity.io/images/ebeyjpve/production/46c319e42d65f53e16523618b06c815b76720cf5-397x272.svg"
                        width="500"
                        height="260"
                      />
                    </div>
                    <div
                      class="show-for-medium-up performance-section__graphic explaining-section__graphic"
                    >
                      <div id="performance-records" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__copy">
                    <h2
                      class="explaining-section__headline headline ng-binding"
                    >
                      {copy_section.headline}
                    </h2>
                    <div class="explaining-section__body">
                      <PortableText value={copy_section.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/if}

    {#if copy_section.section_type == "private-data"}
      <section
        class="explaining-section private-data"
        data-ui-component="privacy-free"
      >
        <div class="container-fluid">
          <div class="row center-md">
            <div class="col-sm-12 col-md-11">
              <div class="row middle-md reverse-md">
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__graphic-container">
                    <div class="hide-for-medium-up explaining-section__graphic">
                      <img
                        alt="Line art illustration of a lock protecting binary code to represent a Pogo LT user's protected data."
                        loading="lazy"
                        src="https://cdn.sanity.io/images/ebeyjpve/production/eb09e519b627c511abb6f2310f3b660268f434e7-274x281.svg"
                        width="500"
                        height="250"
                      />
                    </div>
                    <div class="show-for-medium-up explaining-section__graphic">
                      <div id="privacy-free" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="explaining-section__copy">
                    <h2
                      class="explaining-section__headline headline ng-binding"
                    >
                      {copy_section.headline}
                    </h2>
                    <div class="explaining-section__body">
                      <PortableText value={copy_section.body} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    {/if}
  {/each}
</div>

<style>
.explaining-sections {
  margin-bottom: 1.5rem;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .explaining-sections {
    margin-bottom: 16rem;
  }
}

@media only screen and (min-width: 961px) {
  .explaining-sections {
    margin-bottom: 10rem;
  }
}

@media only screen and (min-width: 768px) {
  .explaining-section {
    padding-bottom: 0;
  }
}

.explaining-section .explaining-section__copy {
  z-index: 1;
  position: relative;
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 768px) {
  .explaining-section .explaining-section__copy {
    margin-bottom: 2.25rem;
  }
}

@media only screen and (min-width: 961px) {
  .explaining-section .explaining-section__body {
    max-width: 44ch;
  }
}

.explaining-section .explaining-section__headline {
  max-width: 17.5rem;
  font-size: var(--h3);
  line-height: 1.2;
  font-family: var(--secondary-font-family);
}

@media only screen and (min-width: 768px) {
  .explaining-section .explaining-section__headline {
    max-width: none;
    font-size: var(--h3);
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) {
  .explaining-section .explaining-section__graphic-container {
    position: relative;
    width: 100%;
    padding-bottom: 135%;
    margin-bottom: -1.5rem;
  }
}

@media only screen and (min-width: 961px) {
  .explaining-section .explaining-section__graphic-container {
    padding-bottom: 126%;
  }
}

.explaining-section .explaining-section__graphic {
  margin-top: 0;
  margin-bottom: 1.5rem;
  position: relative;
}

@media only screen and (min-width: 358px) {
  .explaining-section .explaining-section__graphic {
    margin-bottom: 2.25rem;
  }
}

@media only screen and (min-width: 768px) {
  .explaining-section .explaining-section__graphic {
    max-width: none;
    width: 220%;
    left: -10%;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}

@media only screen and (min-width: 961px) {
  .explaining-section .explaining-section__graphic {
    width: 220%;
    left: -10%;
    right: -10%;
    min-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .easy-reports .explaining-section__copy {
    top: 3rem;
  }
}

@media only screen and (min-width: 961px) {
  .easy-reports .explaining-section__copy {
    top: 3.75rem;
    left: 3.375rem;
  }

  .easy-reports .explaining-section__headline {
    max-width: 20rem;
  }
}

.easy-reports .easy-reports__graphic {
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .easy-reports .easy-reports__graphic {
    max-width: none;
    padding-bottom: 0;
    right: -114%;
    left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .visible-section .explaining-section__copy {
    top: 13.5vw;
  }
}

@media only screen and (min-width: 961px) {
  .visible-section .explaining-section__copy {
    left: 1.5rem;
    top: 6vw;
  }

  .visible-section .explaining-section__headline {
    max-width: 19rem;
  }
}

@media only screen and (min-width: 961px) {
  .visible-section .explaining-section__graphic-container {
    margin-bottom: -1.4rem;
  }
}

.visible-section .visible-section__graphic {
  left: -1.25%;
}

@media only screen and (min-width: 358px) {
  .visible-section .visible-section__graphic {
    max-width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .visible-section .visible-section__graphic {
    left: -105%;
    padding-bottom: 0;
    max-width: none;
  }
}

@media only screen and (min-width: 961px) {
  .visible-section .visible-section__graphic {
    left: -104.8%;
  }
}

@media only screen and (min-width: 768px) {
  .performance-section .explaining-section__copy {
    top: 20vw;
  }
}

@media only screen and (min-width: 961px) {
  .performance-section .explaining-section__copy {
    left: 3.375rem;
    top: 12.5vw;
  }

  .performance-section .explaining-section__headline {
    max-width: 18rem;
  }
}

.performance-section .performance-section__graphic {
  max-width: 20rem;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .performance-section .performance-section__graphic {
    max-width: none;
    left: -5.7%;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .private-data {
    margin-bottom: 6.75rem;
  }
}

.private-data .explaining-section__headline {
  max-width: 25rem;
}

@media only screen and (min-width: 768px) {
  .private-data .explaining-section__copy {
    top: 15vw;
  }
}

@media only screen and (min-width: 961px) {
  .private-data .explaining-section__copy {
    top: 8.5vw;
    left: 1.5rem;
  }

  .private-data .explaining-section__headline {
  }
}

.private-data .explaining-section__graphic {
  max-width: 18.25rem;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 768px) {
  .private-data .explaining-section__graphic {
    max-width: none;
    left: -115%;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 961px) {
  .private-data .explaining-section__graphic {
    left: -120%;
  }
}
</style>
