<script>
import IntersectionObserver from "svelte-intersection-observer";
export let section;

let embed;
let iframeContainer;
let videoLoaded = false;

function loadVideo(e) {
  embed = section.iframe;
  let html = embed;
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  // Extract the URL from the iframe HTML string
  const iframeSrc = tempElement.querySelector("iframe").src;

  // Create a new iframe element and set its src attribute
  const iframe = document.createElement("iframe");
  iframe.src = iframeSrc;
  iframe.frameBorder = "0";
  iframe.title = section.name;
  iframe.setAttribute("autoplay", "true");

  // Append the new iframe element to the body
  iframeContainer.appendChild(iframe);

  setTimeout(() => {
    videoLoaded = true;
  }, 100);
}
</script>

<IntersectionObserver
  element={iframeContainer}
  threshold={0.45}
  on:intersect={loadVideo}
>
  <section class="home-video-section">
    <div class="container-fluid">
      <div class="row center-md">
        <div class="col-sm-12 col-md-10 col-lg-12">
          <div class="home-video-section__inner">
            <div
              class="home-video-section__video responsive-video"
              class:is-visible={videoLoaded}
              bind:this={iframeContainer}
            >
              <div
                class="home-video-section__poster"
                class:is-hidden={videoLoaded}
                data-iframe={embed}
              >
                <img
                  src={`${section.poster.url}?w=500&auto=format`}
                  alt={section.poster.alt_text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</IntersectionObserver>

<style>
section {
  height: 40rem;
}
.home-video-section .home-video-section__inner {
  max-width: 58rem;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 961px) {
  .home-video-section .home-video-section__inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.home-video-section__video {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 52.36%;
  width: 100%;
  position: relative;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tertiary-color);
  filter: blur(3px);
  transition: all 0.2s ease-in-out;
}
.home-video-section__video video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 0 14px -1px rgba(28, 28, 51, 0.45);
}

.home-video-section__poster {
  width: 100%;
  position: relative;
}

.home-video-section__poster iframe,
.home-video-section__poster {
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  right: 0;
  transition: all 0.5s ease-in-out;
}

.home-video-section__poster.is-hidden {
  opacity: 0;
}

.home-video-section__video.is-visible {
  filter: blur(0);
}

.home-video-section__poster img {
  position: absolute;
  left: 0;
  top: 0;
  width: 98%;
  height: 100%;
  right: 0;
  margin: auto;
}

button {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
