<script>
import { onMount } from "svelte";
import { PortableText } from "@portabletext/svelte";
import internalLink from "../custom-marks/internal-link.svelte";
import externalLink from "../custom-marks/external-link.svelte";
import mailtoLink from "../custom-marks/mailto-link.svelte";
import Swiper, { Navigation, Pagination, A11y } from "swiper";

export let section;

let ScrollTrigger;
let gsap;
let tl;
let usingPogoSlideshow;

onMount(async () => {
  const loadModules = async () => {
    const module = await import("gsap");
    gsap = module.default;

    const scrollTriggerModule = await import("gsap/ScrollTrigger");
    ScrollTrigger = scrollTriggerModule.ScrollTrigger;
    const ScrollToPlugin = await import("gsap/ScrollToPlugin");
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  };

  await loadModules();

  const sectionAnims = {
    selectors: {
      steps: '[data-ui-component="using-pogo-steps"]',
      step: '[data-ui-component="using-pogo-step"]',
      stepsCopy: '[data-ui-component="using-pogo-steps-copy"]',
      stepsImages: '[data-ui-component="steps-images"]',
      usingPogo: '[data-ui-component="using-pogo"]',
      timeline: '[data-ui-component="using-pogo-timeline"]',
      downloadStepImage: '[data-ui-component="0-step-image"]',
      gmailStepImage: '[data-ui-component="1-step-image"]',
      loginStepImage: '[data-ui-component="2-step-image"]',
      exportStepImage: '[data-ui-component="3-step-image"]',
      counterButtons: '[data-ui-component="counter-buttons-using-pogo"]',
    },
    animationDuration: "200%",
    init() {
      this.mediaQueries = {
        mediumUp: window.matchMedia("(min-width: 700px)"),
        largeUp: window.matchMedia("(min-width: 961px)"),
      };

      let previousMatch = this.mediaQueries.largeUp.matches;

      const handleResize = () => {
        const currentMatch = this.mediaQueries.largeUp.matches;

        if (currentMatch !== previousMatch) {
          if (this.mediaQueries.largeUp.matches) {
            sectionAnims.initDesktop();
          } else {
            sectionAnims.initMobile();
          }
        }

        previousMatch = currentMatch;
      };

      const checkMediaQueryAndInit = () => {
        if (this.mediaQueries.largeUp.matches) {
          sectionAnims.initDesktop();
        } else {
          sectionAnims.initMobile();
        }
      };

      checkMediaQueryAndInit();
      window.addEventListener("resize", handleResize);
    },
    initDesktop: function () {
      let animationDuration = this.animationDuration;

      if (tl) {
        tl.kill();
      }

      if (usingPogoSlideshow) {
        usingPogoSlideshow.destroy(true, true);
        this.cleanupSlideshow();
      }

      if (ScrollTrigger) {
        ScrollTrigger.refresh();
        ScrollTrigger.getAll().forEach((trigger) => {
          if (trigger.trigger !== this.selectors.usingPogo) {
            trigger.kill();
            // Clean up any inline styles generated by GSAP ScrollTrigger natively
            gsap.set(trigger.trigger, { clearProps: "all" });
          }
        });
      }
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.selectors.usingPogo,
          start: "top 25%",
          end: `+=${animationDuration}`,
          pin: true,
          scrub: 1,
          anticipatePin: 1,
          onUpdate: (self) => {
            if (self.progress === 0) {
              console.log("progress is 0");
              document
                .querySelectorAll(this.selectors.step)[0]
                .classList.remove("is-checked");
            }
          },
        },
      });
      tl.fromTo(
        this.selectors.timeline,
        {
          height: 0,
        },
        {
          height: ".05%",
          onComplete: () => {
            document
              .querySelectorAll(this.selectors.step)[0]
              .classList.add("is-checked");
          },
        }
      )
        .to(this.selectors.timeline, {
          height: "35%",
          onUpdate: () => {
            document
              .querySelectorAll(this.selectors.step)[1]
              .classList.remove("is-checked");
          },
          onComplete: () => {
            document
              .querySelectorAll(this.selectors.step)[1]
              .classList.add("is-checked");
          },
        })
        .fromTo(
          '[data-step-image="0"]',
          { opacity: 1 },
          { opacity: 0, duration: 0 }
        )
        .fromTo(
          '[data-step-image="1"]',
          { opacity: 0 },
          { opacity: 1, duration: 0 }
        )
        .to(this.selectors.timeline, {
          height: "60%",
          onUpdate: () => {
            document
              .querySelectorAll(this.selectors.step)[2]
              .classList.remove("is-checked");
          },
          onComplete: () => {
            document
              .querySelectorAll(this.selectors.step)[2]
              .classList.add("is-checked");
          },
        })
        .fromTo(
          '[data-step-image="1"]',
          { opacity: 1 },
          { opacity: 0, duration: 0 }
        )
        .fromTo(
          '[data-step-image="2"]',
          { opacity: 0 },
          { opacity: 1, duration: 0 }
        )
        .to(this.selectors.timeline, {
          height: "98%",
          onUpdate: () => {
            document
              .querySelectorAll(this.selectors.step)[3]
              .classList.remove("is-checked");
          },
          onComplete: () => {
            document
              .querySelectorAll(this.selectors.step)[3]
              .classList.add("is-checked");
          },
        })
        .fromTo(
          '[data-step-image="2"]',
          { opacity: 1 },
          { opacity: 0, duration: 0 },
          ">"
        )
        .fromTo(
          '[data-step-image="3"]',
          { opacity: 0 },
          { opacity: 1, duration: 0 },
          ">"
        )
        .to(this.selectors.timeline, { height: "100%" });

      let setupButtons = (pTimeline) => {
        const counterButtons = document.querySelectorAll(
          this.selectors.counterButtons
        );

        counterButtons.forEach((elem) => {
          elem.addEventListener("click", (event) => {
            event.preventDefault();
            const step = elem.dataset.id;
            const positions = {
              "0": 0,
              "1": 0.33,
              "2": 0.66,
              "3": 1,
            };
            const scrollPosition =
              pTimeline.scrollTrigger.start +
              (pTimeline.scrollTrigger.end - pTimeline.scrollTrigger.start) *
                positions[step];

            gsap.to(window, {
              duration: 1,
              scrollTo: scrollPosition,
              ease: "power2.inOut",
            });
          });
        });
      };

      setupButtons(tl);
    },

    initMobile: function () {
      if (ScrollTrigger) {
        ScrollTrigger.refresh();
        ScrollTrigger.getAll().forEach((trigger) => {
          if (trigger.trigger !== this.selectors.usingPogo) {
            trigger.kill();
            // Clean up any inline styles generated by GSAP ScrollTrigger natively
            gsap.set(trigger.trigger, { clearProps: "all" });
          }
        });

        if (tl) {
          tl.kill();
        }
      }

      Swiper.use([Navigation, Pagination, A11y]);
      let $slideshow = document.querySelector(this.selectors.steps);
      let $slides = document.querySelectorAll(this.selectors.step);

      var swiperComponents =
        '<div class="swiper-pagination"></div> <div class="swiper-button-prev"></div> <div class="swiper-button-next"></div>';

      $slideshow.insertAdjacentHTML("beforeend", swiperComponents);

      $slideshow.classList.add("swiper-container");
      let $slidesWrapper = document.createElement("div");

      $slidesWrapper.classList.add("swiper-wrapper");

      function wrapAll(elements, wrapper) {
        elements[0].parentNode.insertBefore(wrapper, elements[0]);
        for (let i = 0, len = elements.length; i < len; i++) {
          wrapper.appendChild(elements[i]);
        }
      }

      wrapAll($slides, $slidesWrapper);

      $slides.forEach((elem) => {
        elem.classList.add("swiper-slide");
      });

      usingPogoSlideshow = new Swiper(this.selectors.steps, {
        direction: "horizontal",
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    },

    cleanupSlideshow: function () {
      const $slideshow = document.querySelector(this.selectors.steps);
      if ($slideshow) {
        $slideshow.classList.remove("swiper-container");
        const $swiperComponents = $slideshow.querySelectorAll(
          ".swiper-pagination, .swiper-button-prev, .swiper-button-next"
        );
        $swiperComponents.forEach((elem) => elem.remove());

        const $slidesWrapper = $slideshow.querySelector(".swiper-wrapper");
        if ($slidesWrapper) {
          while ($slidesWrapper.firstChild) {
            $slideshow.appendChild($slidesWrapper.firstChild);
          }
          $slidesWrapper.remove();
        }

        const $slides = $slideshow.querySelectorAll(".swiper-slide");
        $slides.forEach((elem) => {
          elem.classList.remove("swiper-slide");
          elem.removeAttribute("style");
        });
      }
    },
  };

  sectionAnims.init();
});
</script>

<section>
  <div class="container-fluid no-padding-sm">
    <div class="using-pogo">
      <div class="row center-md">
        <div class="col-sm-12 col-lg-12" style="">
          <div class="row middle-md" data-ui-component="using-pogo">
            <div class="col-sm-12 col-lg-6">
              <div class="using-pogo__copy">
                <div class="using-pogo__header">
                  <h2 class="headline using-pogo__headline">
                    {section.headline}
                  </h2>
                </div>
                <div
                  class="using-pogo__steps"
                  data-ui-component="using-pogo-steps"
                >
                  <div class="using-pogo__steps__timeline show-for-large-up">
                    <div
                      data-ui-component="using-pogo-timeline"
                      style="height: 0px;"
                    />
                  </div>

                  {#each section.list as step, index}
                    <div
                      class="using-pogo__steps__step ng-scope"
                      data-ui-component="using-pogo-step"
                      data-step={index}
                    >
                      <div
                        class="using-pogo__steps__step__image hide-for-large-up"
                      >
                        <img
                          srcset="
                            {step.imageUrl}?auto=format&width=600 600w,
                            {step.imageUrl}?auto=format&width=400 400w,
                            {step.imageUrl}?auto=format&width=300 300w"
                          sizes="(min-width: 961px) 800px, (min-width: 768px) 50vw - 4rem, calc(100vw - 6rem)"
                          src="{step.imageUrl}?auto=format&width=100"
                          alt={step.screen_image.asset.alt}
                          class="secure-section__image__background"
                          aria-hidden="true"
                          loading="lazy"
                          role="presentation"
                          width="400"
                          height="100%"
                        />
                      </div>

                      <div
                        tabindex="0"
                        role="button"
                        class="using-pogo__steps__step__counter"
                        data-id={index}
                        aria-label="Scroll to step {index} on using Pogo LT"
                        data-ui-component="counter-buttons-using-pogo"
                      />
                      <div>
                        <PortableText
                          value={step.body}
                          components={{
                            marks: {
                              internalLink: internalLink,
                              externalLink: externalLink,
                              mailtoLink: mailtoLink,
                            },
                          }}
                        />
                      </div>
                    </div>
                  {/each}
                </div>
              </div>
            </div>
            <div class="col-lg-6 show-for-large-up">
              <div
                class="using-pogo__steps-images"
                data-ui-component="steps-images"
              >
                {#each section.list as step, index}
                  <div
                    class="using-pogo__steps-images__image ng-scope"
                    class:is-active={index === 0}
                    data-step-image={index}
                    data-ui-component="{index}-step-image"
                  >
                    <img
                      srcset="
                    {step.imageUrl}?auto=format&width=700 700w,
                    {step.imageUrl}?auto=format&width=600 600w,
                    {step.imageUrl}?auto=format&width=400 400w,
                    {step.imageUrl}?auto=format&width=300 300w"
                      sizes="(min-width: 961px) 800px, (min-width: 768px) 50vw - 4rem, calc(100vw - 6rem)"
                      src="{step.imageUrl}?auto=format&width=100"
                      class="secure-section__image__background"
                      aria-hidden="true"
                      loading="lazy"
                      role="presentation"
                      alt={step.screen_image.asset.alt}
                    />
                  </div>
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.using-pogo {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .using-pogo {
    margin-bottom: 2.25rem;
  }
}

.using-pogo .using-pogo__header {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__header {
    text-align: left;
  }
}

.using-pogo .using-pogo__header .using-pogo__headline {
  font-size: var(--h3);
  line-height: var(--headline-line-height);
  font-family: var(--secondary-font-family);
}

@media only screen and (min-width: 358px) {
  .using-pogo .using-pogo__header .using-pogo__headline {
    font-size: var(--h2);
  }
}

@media only screen and (min-width: 358px) and (min-width: 961px) {
  .using-pogo .using-pogo__header .using-pogo__headline {
    font-size: var(--h1);
  }
}

.using-pogo .using-pogo__steps__step {
  text-align: center;
  color: var(--body-color);
  padding-bottom: 3.25rem;
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps__step {
    text-align: left;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 27rem;
    max-height: 8rem;
    min-height: 7rem;
    max-height: 7.5rem;
  }
}

.using-pogo .using-pogo__steps__step .using-pogo__steps__step__image {
  width: 85%;
  padding: 2.25rem 1.875rem 80%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .using-pogo .using-pogo__steps__step .using-pogo__steps__step__image {
    padding-bottom: 50%;
    margin-bottom: 1.125rem;
  }
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps__step .using-pogo__steps__step__image {
    display: none;
  }
}

.using-pogo .using-pogo__steps__step .using-pogo__steps__step__image img {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .using-pogo .using-pogo__steps__step .using-pogo__steps__step__image img {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    max-width: 34rem;
  }
}

.using-pogo .using-pogo__steps__step p {
  max-width: 21rem;
  margin-right: auto;
  margin-left: auto;
  font-size: 1rem;
  line-height: 1.5;
}

@media only screen and (min-width: 768px) {
  .using-pogo .using-pogo__steps__step p {
    max-width: 28rem;
    font-size: 1.125rem;
    line-height: 1.45;
  }
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps__step p {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    max-width: 26rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.using-pogo .using-pogo__steps__step.is-checked {
  color: var(--secondary-color);
}

.using-pogo
  .using-pogo__steps__step.is-checked
  .using-pogo__steps__step__counter {
  border-color: var(--primary-color);
}

.using-pogo
  .using-pogo__steps__step.is-checked
  .using-pogo__steps__step__counter:after {
  transform: scale(1);
}

.using-pogo [data-step="gmail"] .using-pogo__steps__step__image {
  padding-bottom: 90%;
}

@media only screen and (min-width: 768px) {
  .using-pogo [data-step="gmail"] .using-pogo__steps__step__image {
    max-width: 72%;
    padding-bottom: 60%;
  }
}

.using-pogo .using-pogo__steps {
  position: relative;
}

.using-pogo .using-pogo__steps .using-pogo__steps__timeline {
  display: none;
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps .using-pogo__steps__timeline {
    display: block;
    position: absolute;
    top: 1.875rem;
    left: 1rem;
    height: 70%;
    bottom: 0;
    width: 3px;
    background-color: var(--tertiary-color);
    z-index: 0;
    transition: all 0.2s ease-in-out;
  }
}

.using-pogo .using-pogo__steps .using-pogo__steps__timeline div {
  background-color: var(--primary-color);
  width: 100%;
  height: 0;
}

.using-pogo .using-pogo__steps__step__counter {
  border-radius: 100%;
  border: 3px solid var(--tertiary-color);
  height: 2.375rem;
  width: 2.375rem;
  margin-right: 1rem;
  flex: none;
  z-index: 1;
  background-color: #fff;
  display: none;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps__step__counter {
    display: block;
    height: 2rem;
    width: 2rem;
  }

  .using-pogo .using-pogo__steps__step__counter:after {
    content: " ";
    width: 84%;
    height: 84%;
    position: absolute;
    background-color: var(--accent-color);
    border-radius: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps-images {
    position: relative;
    height: 31.25rem;
    display: flex;
    align-items: center;
  }
}

:global(
    .using-pogo .using-pogo__steps-images .using-pogo__steps-images__image
  ) {
  position: absolute;
  z-index: 0;
  width: 94%;
  margin: auto;
  right: 0;
  left: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 961px) {
  .using-pogo .using-pogo__steps-images .using-pogo__steps-images__image {
    width: 83%;
  }
}

.using-pogo .using-pogo__steps-images .using-pogo__steps-images__image img {
  width: 100%;
}

:global(
    .using-pogo
      .using-pogo__steps-images
      .using-pogo__steps-images__image.is-active
  ) {
  z-index: 1;
  opacity: 1;
}

@media only screen and (min-width: 961px) {
  .using-pogo
    .using-pogo__steps-images
    .using-pogo__steps-images__image:nth-child(2),
  .using-pogo
    .using-pogo__steps-images
    .using-pogo__steps-images__image:nth-child(3) {
    width: 90%;
  }
}

@media only screen and (min-width: 961px) {
  .using-pogo
    .using-pogo__steps-images
    .using-pogo__steps-images__image:nth-child(4) {
    width: 98%;
  }
}

.using-pogo .using-pogo__steps-images [data-step-image="gmail"] {
  width: 84%;
}

:global(.using-pogo .swiper-pagination) {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}

:global(.using-pogo .swiper-pagination-bullet) {
  height: 1.875rem;
  width: 1.875rem;
  background-color: #fff;
  opacity: 1 !important;
  position: relative;
  border: 2px solid #adadad;
}

:global(.using-pogo .swiper-container .swiper-button-next),
:global(.using-pogo .swiper-container .swiper-button-prev) {
  color: var(--primary-color);
  top: auto;
  bottom: 12px;
}

:global(.using-pogo .swiper-container .swiper-button-next:after),
:global(.using-pogo .swiper-container .swiper-button-prev:after) {
  font-size: 1.5rem;
}

@media only screen and (min-width: 768px) {
  :global(.using-pogo .swiper-container .swiper-button-next:after),
  :global(.using-pogo .swiper-container .swiper-button-prev:after) {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 768px) {
  :global(.using-pogo .swiper-container .swiper-button-next),
  :global(.using-pogo .swiper-container .swiper-button-prev) {
    top: 14.75rem;
  }
}

:global(.using-pogo .swiper-container .swiper-button-prev) {
  left: 1.5rem;
}

:global(.using-pogo .swiper-container .swiper-button-next) {
  right: 1.5rem;
}

:global(.using-pogo .swiper-container .swiper-button-disabled) {
  opacity: 0;
}

:global(.using-pogo .swiper-container .swiper-pagination-bullet) {
  height: 1.875rem;
  width: 1.875rem;
  background-color: #fff;
  opacity: 1 !important;
  position: relative;
  border: 2px solid #adadad;
}

:global(.using-pogo .swiper-container .swiper-pagination-bullet:after) {
  content: " ";
  background-color: #f4eded;
  width: 85%;
  height: 85%;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(0);
}

:global(
    .using-pogo
      .swiper-container
      .swiper-pagination-bullet.swiper-pagination-bullet-active
  ) {
  border: 2px solid var(--primary-color);
}

:global(
    .using-pogo
      .swiper-container
      .swiper-pagination-bullet.swiper-pagination-bullet-active:after
  ) {
  transform: scale(1);
}
</style>
