<script>
import { page } from "$app/stores";
import { MetaTags } from "svelte-meta-tags";
import SectionHeroHeader from "$lib/components/sections/sctn-hero-header.svelte";
import SectionVideoEmbed from "$lib/components/sections/sctn-video-embed.svelte";
import SectionNarrative from "$lib/components/sections/sctn-narrative.svelte";
import SectionDownloadCta from "$lib/components/sections/sctn-download-cta.svelte";
import SectionNewsletterSignup from "$lib/components/sections/sctn-newsletter-signup.svelte";
import SectionNothingToInstall from "$lib/components/sections/sctn-nothing-to-install.svelte";
import SectionUsingApp from "$lib/components/sections/sctn-using-app.svelte";
import SectionBeforeUsingApp from "$lib/components/sections/sctn-before-using-app.svelte";
import { onMount } from "svelte";
export let data;

onMount(async () => {
  // // Add ScrollMagicPluginGsap to the controller instance
  // ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  // content = await data.streamed.content;
});
</script>

<!-- <MetaTags
  title={$page.data.siteSettings.seo.title}
  description={$page.data.siteSettings.seo.description}
  canonical="https://www.pogo-lt.org"
  openGraph={{
    url: "https://www.pogo-lt.org",
    title: $page.data.siteSettings.seo.title,
    description: $page.data.siteSettings.seo.description,
    images: [
      {
        url: urlFxor($page.data.siteSettings.seo.banner_image.asset).width(1200),
        width: 800,
        height: 600,
        alt: $page.data.siteSettings.seo.banner_image.alt_text
          ? $page.data.siteSettings.seo.banner_image.alt_text
          : "Missing Alt Text",
      },
    ],
    site_name: $page.data.siteSettings.seo.title,
  }}
  twitter={{
    site: "www.pogo-lt.org",
    cardType: "summary_large_image",
    title: $page.data.siteSettings.seo.title,
    description: $page.data.siteSettings.seo.description,
    image: urxlFor($page.data.siteSettings.seo.banner_image.asset).width(1200),
    imageAlt: $page.data.siteSettings.seo.banner_image.alt_text
      ? $page.data.siteSettings.seo.banner_image.alt_text
      : "Missing Alt Text",
  }}
/> -->

{#each data.content.page_layout as section}
  {#if section._type == "section_hero_header"}
    <SectionHeroHeader {section} />
  {/if}
  {#if section._type == "section_video_embed"}
    <SectionVideoEmbed {section} />
  {/if}
  {#if section._type == "section_download_cta"}
    <SectionDownloadCta {section} />
  {/if}

  {#if section._type == "section_newsletter_signup"}
    <SectionNewsletterSignup {section} />
  {/if}

  {#if section._type == "section_narrative"}
    <SectionNarrative {section} />
  {/if}

  {#if section._type == "section_nothing_to_install"}
    <SectionNothingToInstall {section} />
  {/if}

  {#if section._type == "section_using_app"}
    <SectionUsingApp {section} />
  {/if}

  {#if section._type == "section_before_using_app"}
    <SectionBeforeUsingApp {section} />
  {/if}
{/each}
